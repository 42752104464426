import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { api_url } from "../../config";
import { useEffect } from "react";

const initialState = {
  user: "",
  token: "",
  loading: false,
  error: "",
  message: "",
  userDetail: "",
  IsLogin: false,
};

const loginToken = localStorage.getItem("login_token");
console.log("token", loginToken);

// sign up

export const signupUser = createAsyncThunk(
  "signupUser",
  async (userdata, { rejectWithValue }) => {
    console.log("data12", userdata);

    try {
      const response = await axios.post(`${api_url}/api/auth/signup`, userdata);
      console.log("redux-res", response);

      if (response.status === 404) {
        return rejectWithValue("API endpoint not found");
      }

      return response;
    } catch (error) {
      console.log("redux-error", error);

      // console.log("rejectWithValue value:", error.message);

      return rejectWithValue(error);
    }
  }
);

//otp Register Verify

export const otpRegisterVerify = createAsyncThunk(
  "otpVerify",
  async (userdata, { rejectWithValue }) => {
    console.log("data12", userdata);
    try {
      const response = await axios.post(
        `${api_url}/api/auth/register_otp_verify`,
        userdata
      );
      const result = await response;
      console.log("redux-res", result);
      return result;
    } catch (error) {
      console.log("redux-error", error);
      return rejectWithValue(error);
    }
  }
);

//user login

export const userLogin = createAsyncThunk(
  "userLogin",
  async (userdata, { rejectWithValue }) => {
    console.log("data12", userdata);
    try {
      const response = await axios.post(`${api_url}/api/auth/login`, userdata);
      const result = await response;
      console.log("redux-res", result);
      return result;
    } catch (error) {
      console.log("redux-error", error);
      return rejectWithValue(error);
    }
  }
);

//otp login Verify

export const otpLoginVerify = createAsyncThunk(
  "userLogin",
  async (userdata, { rejectWithValue }) => {
    console.log("data12", userdata);
    try {
      const response = await axios.post(
        `${api_url}/api/auth/login_otp_verify`,
        userdata
      );
      const result = await response;
      console.log("redux-res", result);
      return result;
    } catch (error) {
      console.log("redux-error", error);
      return rejectWithValue(error);
    }
  }
);

//user logout

export const userLogout = createAsyncThunk(
  "showUserDetail",
  async (_, { rejectWithValue }) => {
    try {
      const loginToken = localStorage.getItem("login_token");
      const response = await axios.post(
        `${api_url}/api/auth/logout`,
        {},
        {
          headers: {
            key: loginToken,
          },
        }
      );
      const result = await response;
      console.log("redux-res", result);
      return result;
    } catch (error) {
      console.log("redux-error", error);
      return rejectWithValue(error);
    }
  }
);

//show user

export const showUserDetail = createAsyncThunk(
  "showUserDetail",
  async (_, { rejectWithValue }) => {
    try {
      const loginToken = localStorage.getItem("login_token");
      const response = await axios.post(
        `${api_url}/api/auth/user_info`,
        {},
        {
          headers: {
            key: loginToken,
          },
        }
      );
      const result = await response;
      console.log("redux-res", result);
      return result;
    } catch (error) {
      console.log("redux-error", error);
      return rejectWithValue(error);
    }
  }
);

const authSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: {
    [signupUser.pending]: (state, action) => {
      state.loading = true;
    },
    [signupUser.fulfilled]: (state, action) => {
      state.loading = true;
      state.message = action.payload.data.message;
      state.user = action.payload.data;
      state.loading = false;
    },
    [signupUser.rejected]: (state, action) => {
      state.loading = false;
      state.user = action.payload.response.data;
      // state.message = action.payload.response.message;

      state.error = [];
      if (action.payload.response.data.data.phone) {
        state.error.push(action.payload.response.data.data.phone[0]);
      }

      if (action.payload.response.data.data.email) {
        state.error.push(action.payload.response.data.data.email[0]);
      }
    },
    [otpRegisterVerify.pending]: (state, action) => {
      state.loading = true;
    },
    [otpRegisterVerify.fulfilled]: (state, action) => {
      state.loading = true;
      state.message = action.payload.data.message;
      state.user = action.payload.data;
      state.token = action.payload.data.data.token;
      state.loading = false;
    },
    [otpRegisterVerify.rejected]: (state, action) => {
      state.loading = false;
      state.error = "Please enter Valid OTP";
    },
    [userLogin.pending]: (state, action) => {
      state.loading = true;
    },
    [userLogin.fulfilled]: (state, action) => {
      state.loading = true;
      state.message = action.payload.data.message;
      state.user = action.payload.data;
      state.loading = false;
    },
    [userLogin.rejected]: (state, action) => {
      state.loading = false;
      // state.error = action.payload.response.data.message;
      state.error = action.error;
    },
    [otpLoginVerify.pending]: (state, action) => {
      state.loading = true;
    },
    [otpLoginVerify.fulfilled]: (state, action) => {
      state.loading = true;
      state.message = action.payload.data.message;
      state.user = action.payload.data;
      state.token = action.payload.data.data.token;
      state.loading = false;
    },
    [otpLoginVerify.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.response.data.message;
    },

    [showUserDetail.pending]: (state, action) => {
      state.loading = true;
    },
    [showUserDetail.fulfilled]: (state, action) => {
      state.loading = true;
      state.userDetail = action.payload.data;
      state.message = "";
      state.loading = false;
    },
    [showUserDetail.rejected]: (state, action) => {
      state.loading = false;
    },
    [userLogout.pending]: (state, action) => {
      state.loading = true;
    },
    [userLogout.fulfilled]: (state, action) => {
      state.loading = true;
      state.user = action.payload.data;
      state.message = "Logout Succefully!";
      state.IsLogin = false;
    },
    [userLogout.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export default authSlice.reducer;
