import { configureStore } from "@reduxjs/toolkit";
import clickActionReducer from "../stateSlice/clickActionSlice";
import authSlice from "../stateSlice/authSlice";

const store = configureStore({
  reducer: {
    clickAction: clickActionReducer,
    user: authSlice,
  },
});

export default store;
