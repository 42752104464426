import React, { Fragment, Suspense, useEffect, useState } from "react";
import Preloader from "../elements/Preloader";
import { faMobileAlt } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import {
  otpLoginVerify,
  showUserDetail,
  userLogin,
} from "../redux/stateSlice/authSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, Link } from "react-router-dom";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";

const Breadcrumb = React.lazy(() => import("../components/Breadcrumb"));
const NavbarOne = React.lazy(() => import("../components/NavbarOne"));
const FooterBottomOne = React.lazy(() =>
  import("../components/FooterBottomOne")
);
const FooterOne = React.lazy(() => import("../components/FooterOne"));

function formatTime(seconds) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds}`;
}

const UserSignin = () => {
  const { user, loading, token, error, message, IsLogin } = useSelector(
    (state) => state.user
  );
  const [userdata, setUserdata] = useState({
    phone: "",
    password: "",
    otp: "",
  });
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState("otp");
  const [loadingBtnShow, setLoadingBtnShow] = useState(false);
  const [otpDisplay, setOtpDisplay] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [resBtnDisable, setResBtnDisable] = useState(false);
  const [isMobValid, setIsMobValid] = useState(true);
  const [isOtpValid, setIsOtpValid] = useState(true);
  const [countdown, setCountdown] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const successNotify = () => {
    toast.success(`${message}`);
    console.log("callsignin suc");
  };
  const errorNotify = () => {
    console.log("Calling errorNotify with error:", error);
    toast.error(`${error}`);
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const handleChange = (e) => {
    setUserdata({ ...userdata, [e.target.id]: e.target.value });
    // console.log(e.target.value);
  };

  const handleOtpSend = () => {
    if (countdown <= 0) {
      setDisabled(true);
      setCountdown(120);
      // Start the countdown timer
      let timer = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown === 1) {
            setDisabled(false);
            clearInterval(timer);
          }
          return prevCountdown - 1;
        });
      }, 1000);
    }
    dispatch(userLogin(userdata));

    errorNotify();
  };

  const handleOtpVerify = () => {
    dispatch(otpLoginVerify(userdata));
    // successNotify();
    errorNotify();
  };

  console.log(user, loading, token, error, IsLogin, message, "rp");
  useEffect(() => {
    if (user) {
      // if (user.success === true) {

      // } else if (user.success === false) {
      //   setOtpDisplay(false);
      //   errorNotify();
      // }

      if (user.message === "OTP sent successfully") {
        setLoadingBtnShow(true);
        setOtpDisplay(true);
        setLoadingBtnShow(false);
        successNotify();
      } else if (user.message !== "OTP sent successfully") {
        setOtpDisplay(false);
      }

      // if (
      //   error === "Please enter correct 6 digits OTP" ||
      //   error === "Your OTP has been expired" || error === "Password is incorrect"
      // ) {
      //   errorNotify();
      // }

      if (
        user.message === "OTP verified successfully and Login successfully." ||
        user.message === "Login Successfully."
      ) {
        // successNotify();
        localStorage.setItem("login_token", user.data.token);
        navigate("/");
      }
    }
  }, [user]);

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  // useEffect(() => {
  //   dispatch(showUserDetail());
  // }, [dispatch]);

  const validateMobileNumber = () => {
    const mobilePattern = /^\d{10}$/;
    const mobilePatternCheck = mobilePattern.test(userdata.phone);

    setIsMobValid(mobilePatternCheck);
    if (!mobilePatternCheck) {
      setResBtnDisable(true);
    } else {
      setResBtnDisable(false);
    }
  };

  const validateOtp = () => {
    const otpPattern = /^\d{6}$/;
    const otpPatternCheck = otpPattern.test(userdata.otp);

    setIsOtpValid(otpPatternCheck);
    if (!otpPatternCheck) {
      setResBtnDisable(true);
    } else {
      setResBtnDisable(false);
    }
  };

  return (
    <>
      <Fragment>
        <Suspense fallback={<Preloader />}>
          <ToastContainer autoClose={2000} />
          <NavbarOne />

          <section
            className=""
            style={{
              backgroundColor: "#eee",
              paddingTop: "180px",
              paddingBottom: "20px",
            }}
          >
            <div className="container h-100">
              <div className="row d-flex justify-content-center align-items-center h-100">
                <div className="col-lg-12 col-xl-11">
                  <div
                    className="card text-black"
                    style={{ borderRadius: "25px" }}
                  >
                    <div className="card-body p-md-5">
                      <div className="row justify-content-center">
                        <div className="col-md-10 col-lg-6 col-xl-7 d-flex align-items-center order-1 order-lg-1">
                          <img
                            src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-registration/draw1.webp"
                            className="img-fluid"
                            alt="Side image"
                          />
                        </div>
                        <div className="col-md-10 col-lg-6 col-xl-5 order-2 order-lg-2">
                          <p className="text-center h1 fw-bold mb-5 mx-1 mx-md-4 mt-4">
                            Sign In
                          </p>

                          <form className="mx-1 mx-md-4">
                            <div className="d-flex flex-row align-items-center">
                              <div className="single-input-inner mb-2 w-100">
                                <input
                                  className="bg-white border"
                                  type="number"
                                  id="phone"
                                  placeholder="Your Mobile No."
                                  maxLength={10}
                                  onInput={(e) =>
                                    (e.target.value = e.target.value.slice(
                                      0,
                                      10
                                    ))
                                  }
                                  value={userdata.phone}
                                  onChange={handleChange}
                                  onBlur={validateMobileNumber}
                                />
                              </div>
                            </div>
                            {!isMobValid && (
                              <p className="text-danger">
                                Please enter a valid Mobile No.
                              </p>
                            )}
                            {otpDisplay && (
                              <div>
                                {selectedOption === "password" && (
                                  <div
                                    style={{
                                      textAlign: "end",
                                      color: "#ff2020",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => handleOptionChange("otp")}
                                  >
                                    Use OTP
                                  </div>
                                )}
                                {selectedOption === "otp" && (
                                  <div
                                    style={{
                                      textAlign: "end",
                                      color: "#ff2020",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      handleOptionChange("password")
                                    }
                                  >
                                    Use Password
                                  </div>
                                )}

                                {selectedOption === "password" && (
                                  <>
                                    <div className="d-flex flex-row align-items-center">
                                      {/* <div className="form-outline flex-fill mb-0">
                                        <input
                                          type="password"
                                          id="password"
                                          className="form-control"
                                          placeholder="Password"
                                          value={userdata.password}
                                          onChange={handleChange}
                                        />
                                        <div className="text-danger mt-2">
                                          {error}
                                        </div>
                                      </div> */}
                                      <div className="single-input-inner mb-2 w-100">
                                        <input
                                          type={
                                            passwordVisible
                                              ? "text"
                                              : "password"
                                          }
                                          className="bg-white border"
                                          id="password"
                                          placeholder="Password"
                                          value={userdata.password}
                                          onChange={handleChange}
                                        />
                                        <label
                                          className={`password-toggle ${
                                            passwordVisible
                                              ? "visible"
                                              : "hidden"
                                          }`}
                                          onClick={togglePasswordVisibility}
                                          style={{
                                            left: "unset",
                                            right: "20px",
                                          }}
                                        >
                                          {passwordVisible ? (
                                            <FaRegEyeSlash />
                                          ) : (
                                            <FaRegEye />
                                          )}
                                        </label>
                                      </div>
                                    </div>
                                    {loadingBtnShow ? (
                                      <button
                                        className="btn btn-base"
                                        style={{
                                          background: "#fa4318",
                                          color: "white",
                                        }}
                                        type="button"
                                        disabled
                                      >
                                        <span
                                          className="spinner-border spinner-border-sm"
                                          aria-hidden="true"
                                        ></span>
                                        <span role="status">Loading...</span>
                                      </button>
                                    ) : (
                                      <button
                                        type="button"
                                        className="btn btn-base btn-lg"
                                        onClick={handleOtpSend}
                                      >
                                        Login
                                      </button>
                                    )}
                                  </>
                                )}

                                {selectedOption === "otp" && (
                                  <>
                                    <div className="d-flex flex-row align-items-center ">
                                      <div className="single-input-inner mb-2 w-100">
                                        <input
                                          className="bg-white border"
                                          type="number"
                                          placeholder="OTP"
                                          id="otp"
                                          value={userdata.otp}
                                          onChange={handleChange}
                                          onInput={(e) =>
                                            (e.target.value =
                                              e.target.value.slice(0, 6))
                                          }
                                          onBlur={validateOtp}
                                        />
                                        {!isOtpValid && (
                                          <p className="text-danger">
                                            Please enter a valid OTP
                                          </p>
                                        )}
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <div className="text-success mt-2">
                                            Your Otp Here {user.data.otp}
                                          </div>
                                          <div className="mt-2">
                                            <button
                                              className="bg-white m-0 p-0"
                                              onClick={handleOtpSend}
                                              style={{
                                                color: "var(--main-color)",
                                                cursor: "pointer",
                                                border: "none",
                                              }}
                                              disabled={disabled}
                                            >
                                              {disabled
                                                ? `Resend in ${formatTime(
                                                    countdown
                                                  )}`
                                                : "Resend OTP"}
                                            </button>
                                          </div>
                                        </div>
                                        
                                        {/* <div className="text-danger mt-2">
                                          {error}
                                        </div> */}
                                      </div>
                                    </div>
                                    {loadingBtnShow ? (
                                      <button
                                        className="btn btn-base"
                                        style={{
                                          background: "#fa4318",
                                          color: "white",
                                        }}
                                        type="button"
                                        disabled
                                      >
                                        <span
                                          className="spinner-border spinner-border-sm"
                                          aria-hidden="true"
                                        ></span>
                                        <span role="status">Loading...</span>
                                      </button>
                                    ) : (
                                      <button
                                        type="button"
                                        className="btn btn-base btn-lg"
                                        onClick={handleOtpVerify}
                                        disabled={resBtnDisable}
                                        style={{
                                          background: "#fa4318",
                                          color: "white",
                                        }}
                                      >
                                        OTP Verify
                                      </button>
                                    )}
                                  </>
                                )}
                              </div>
                            )}
                            {otpDisplay === false && (
                              <>
                                {loadingBtnShow ? (
                                  <button
                                    className="btn btn-base"
                                    style={{
                                      background: "#fa4318",
                                      color: "white",
                                    }}
                                    type="button"
                                    disabled
                                  >
                                    <span
                                      className="spinner-border spinner-border-sm"
                                      aria-hidden="true"
                                    ></span>
                                    <span role="status">Loading...</span>
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="btn btn-base btn-lg"
                                    onClick={handleOtpSend}
                                    disabled={resBtnDisable}
                                    style={{
                                      background: "#fa4318",
                                      color: "white",
                                    }}
                                  >
                                    OTP Send
                                  </button>
                                )}
                              </>
                            )}
                            {/* {otpDisplay ? (
                              <div className="d-flex justify-content-center mx-4 mb-3 mb-lg-4">
                                {loadingBtnShow ? (
                                  <button
                                    className="btn btn-base"
                                    style={{
                                      background: "#fa4318",
                                      color: "white",
                                    }}
                                    type="button"
                                    disabled
                                  >
                                    <span
                                      className="spinner-border spinner-border-sm"
                                      aria-hidden="true"
                                    ></span>
                                    <span role="status">Loading...</span>
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="btn btn-base btn-lg"
                                    onClick={handleOtpVerify}
                                  >
                                    Login
                                  </button>
                                )}
                              </div>
                            ) : (
                              <div className="d-flex justify-content-center mx-4 mb-3 mb-lg-4">
                               
                              </div>
                            )} */}
                            <p className="small fw-bold mt-2 pt-1 mb-0">
                              Don't have an account?{" "}
                              <Link
                                to="/signup"
                                className=""
                                style={{ color: "var(--main-color)" }}
                              >
                                Register
                              </Link>
                            </p>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Footer One */}
          <FooterOne />
          {/* Footer Bottom One */}
          <FooterBottomOne />
        </Suspense>
      </Fragment>
    </>
  );
};

export default UserSignin;
