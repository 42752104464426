import React, { Fragment, Suspense, useEffect, useState } from "react";
import Preloader from "../elements/Preloader";
import { faMobileAlt } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { otpRegisterVerify, signupUser } from "../redux/stateSlice/authSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import {
  FaRegEnvelope,
  FaLaptop,
  FaRegEyeSlash,
  FaRegEye,
} from "react-icons/fa";

const Breadcrumb = React.lazy(() => import("../components/Breadcrumb"));
const NavbarOne = React.lazy(() => import("../components/NavbarOne"));
const FooterBottomOne = React.lazy(() =>
  import("../components/FooterBottomOne")
);
const FooterOne = React.lazy(() => import("../components/FooterOne"));

const UserSignup = () => {
  const { user, loading, token, error, message } = useSelector(
    (state) => state.user
  );
  const [userdata, setUserdata] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    cPassword: "",
    address: "",
    otp: "",
  });

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [loadingBtnShow, setLoadingBtnShow] = useState(loading);
  const [otpDisplay, setOtpDisplay] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [isMobValid, setIsMobValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isNameValid, setIsNameValid] = useState(true);
  const [isAddValid, setIsAddValid] = useState(true);
  const [resBtnDisable, setResBtnDisable] = useState(false)

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const successNotify = () => {
    toast.success(`${message}`);
  };
  const errorNotify = () => {
    toast.error(`${error}`);
  };

  const handleChange = (e) => {
    setUserdata({ ...userdata, [e.target.id]: e.target.value });
    // console.log(e.target.value);
  };

  const handleOtpSend = () => {
    dispatch(signupUser(userdata));
  };

  const handleOtpVerify = () => {
    dispatch(otpRegisterVerify(userdata));
    errorNotify();
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  console.log(user, loading, token, error, message, "rp");
  useEffect(() => {
    if (user) {
      if (user.message === "OTP sent successfully.") {
        successNotify();
        setOtpDisplay(true);
      } else if (user.message !== "OTP sent successfully.") {
        setOtpDisplay(false);
        errorNotify();
      }
      //  if (user.message === 'Please enter Valid OTP') {
      //     errorNotify();
      //   }

      if (user.message === "OTP verified successfully.") {
        navigate("/signin");
      }
    }
  }, [user]);

  const validatePasswords = () => {
    const match = userdata.password === userdata.cPassword;
    setPasswordsMatch(match);
    if (!match) {
      setResBtnDisable(true);
    } else {
      setResBtnDisable(false);
    }
  };

  const validateEmail = () => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const emailValidCheck = emailPattern.test(userdata.email)
    setIsEmailValid(emailValidCheck);
    if (!emailValidCheck) {
      setResBtnDisable(true);
    } else {
      setResBtnDisable(false);
    }
  };

  const validateMobileNumber = () => {
    const mobilePattern = /^\d{10}$/;
    const mobilePatternCheck = mobilePattern.test(userdata.phone);

    setIsMobValid(mobilePatternCheck);
    if (!mobilePatternCheck) {
      setResBtnDisable(true)
    }else{
      setResBtnDisable(false)
    }
  };

  const validateName = () => {
    const namePattern = /\S+/;
    const namePatternCheck = userdata.name !== null && namePattern.test(userdata.name);

    setIsNameValid(namePatternCheck);
    if (!namePattern) {
      setResBtnDisable(true)
    }else{
      setResBtnDisable(false)
    }
  };

  const validateAdd = () => {
    const addPattern = userdata.address !== null && userdata.address.trim() !== '';

    setIsAddValid(addPattern);
    if (!addPattern) {
      setResBtnDisable(true)
    }else{
      setResBtnDisable(false)
    }
  };





  return (
    <>
      <Fragment>
        <Suspense fallback={<Preloader />}>
          <ToastContainer autoClose={2000} />
          <NavbarOne />

          <section
            className=""
            style={{
              backgroundColor: "#eee",
              paddingTop: "180px",
              paddingBottom: "20px",
            }}
          >
            <div className="container h-100">
              <div className="row d-flex justify-content-center align-items-center h-100">
                <div className="col-lg-12 col-xl-11">
                  <div
                    className="card text-black"
                    style={{ borderRadius: "25px" }}
                  >
                    <div className="card-body p-md-5">
                      <div className="row justify-content-center">
                        <div className="col-md-10 col-lg-6 col-xl-7 d-flex align-items-center order-1 order-lg-1">
                          <img
                            src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-registration/draw1.webp"
                            className="img-fluid"
                            alt="Side image"
                          />
                        </div>
                        <div className="col-md-10 col-lg-6 col-xl-5 order-2 order-lg-2">
                          <p className="text-center h1 fw-bold mb-5 mx-1 mx-md-4 mt-4">
                            Sign up
                          </p>

                          <form className="mx-1 mx-md-4">
                            <div className="d-flex flex-row align-items-center">
                              <div className="single-input-inner mb-2 w-100">
                                <input
                                  className="bg-white border"
                                  type="text"
                                  id="name"
                                  placeholder="Your Name"
                                  value={userdata.name}
                                  onChange={handleChange}
                                  onBlur={validateName}
                                />
                              </div>
                            </div>
                            {!isNameValid && (
                                <p className="text-danger">Please enter a valid Name</p>
                              )}
                            <div className="d-flex flex-row align-items-center">
                              <div className="single-input-inner mb-2 w-100">
                                <input
                                  className="bg-white border"
                                  type="email"
                                  id="email"
                                  placeholder="Your Email "
                                  value={userdata.email}
                                  onChange={handleChange}
                                  onBlur={validateEmail}
                                />
                              </div>
                            </div>
                            {!isEmailValid && (
                                <p className="text-danger">Please enter a valid Email</p>
                              )}
                            <div className="d-flex flex-row align-items-center">
                              <div className="single-input-inner mb-2 w-100">
                                <input
                                  className="bg-white border"
                                  type="number"
                                  id="phone"
                                  placeholder="Your Mobile No."
                                  maxLength={10}
                                  onInput={(e) =>
                                    (e.target.value = e.target.value.slice(
                                      0,
                                      10
                                    ))
                                  }
                                  value={userdata.phone}
                                  onChange={handleChange}
                                  onBlur={validateMobileNumber}
                                />
                              </div>
                            </div>
                            {!isMobValid && (
                                <p className="text-danger">Please enter a valid Mobile No.</p>
                              )}
                            <div className="d-flex flex-row align-items-center ">
                              <div className="single-input-inner mb-2 w-100">
                                <input
                                  type={passwordVisible ? "text" : "password"}
                                  className="bg-white border"
                                  id="password"
                                  placeholder="Password"
                                  value={userdata.password}
                                  onChange={handleChange}
                                />
                                <label
                                  className={`password-toggle ${
                                    passwordVisible ? "visible" : "hidden"
                                  }`}
                                  onClick={togglePasswordVisibility}
                                  style={{ left: "unset", right: "20px" }}
                                >
                                  {passwordVisible ? (
                                    <FaRegEyeSlash />
                                  ) : (
                                    <FaRegEye />
                                  )}
                                </label>
                              </div>
                            </div>

                            <div className="d-flex flex-row align-items-center">
                              <div className="single-input-inner mb-2 w-100">
                                <input
                                  type={passwordVisible ? "text" : "password"}
                                  placeholder="Confirm Password"
                                  className="bg-white border"
                                  id="cPassword"
                                  name="cPassword"
                                  value={userdata.cPassword}
                                  onChange={handleChange}
                                  onBlur={validatePasswords}
                                />
                                <label
                                  className={`password-toggle ${
                                    passwordVisible ? "visible" : "hidden"
                                  }`}
                                  onClick={togglePasswordVisibility}
                                  style={{ left: "unset", right: "20px" }}
                                >
                                  {passwordVisible ? (
                                    <FaRegEyeSlash />
                                  ) : (
                                    <FaRegEye />
                                  )}
                                </label>
                              </div>
                            </div>
                              {!passwordsMatch && (
                                <p className="text-danger">Passwords do not match.</p>
                              )}
                            
                            <div className="d-flex flex-row align-items-center">
                              <div className="single-input-inner mb-2 w-100">
                                <input
                                  className="bg-white border"
                                  type="textarea"
                                  id="address"
                                  placeholder="Address"
                                  value={userdata.address}
                                  onChange={handleChange}
                                  onBlur={validateAdd}
                                />
                              </div>
                            </div>
                            {!isAddValid && (
                                <p className="text-danger">Please enter a valid Address</p>
                              )}
                            {otpDisplay && (
                              <div className="d-flex flex-row align-items-center mb-4">
                                <div className="form-outline flex-fill mb-0">
                                  <input
                                    type="number"
                                    className="form-control "
                                    placeholder="OTP"
                                    id="otp"
                                    value={userdata.otp}
                                    onChange={handleChange}
                                    onInput={(e) =>
                                      (e.target.value = e.target.value.slice(
                                        0,
                                        6
                                      ))
                                    }
                                  />
                                  <div className="text-success mt-2">
                                    Your Otp Here {user.data.otp}
                                  </div>
                                  <div className="text-danger mt-2">
                                    {error}
                                  </div>
                                </div>
                              </div>
                            )}
                            <div className="form-check d-flex justify-content-center mb-5">
                              <input
                                className="form-check-input me-2"
                                type="checkbox"
                                value=""
                                id="form2Example3c"
                                style={{}}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="form2Example3"
                              >
                                I agree all statements in
                                <a href="#!"> Terms of service</a>
                              </label>
                            </div>
                            {otpDisplay ? (
                              <div className="d-flex justify-content-center mx-4 mb-3 mb-lg-4">
                                {loadingBtnShow ? (
                                  <button
                                    className="btn btn-base"
                                    style={{
                                      background: "#fa4318",
                                      color: "white",
                                    }}
                                    type="button"
                                    disabled
                                  >
                                    <span
                                      className="spinner-border spinner-border-sm"
                                      aria-hidden="true"
                                    ></span>
                                    <span role="status">Loading...</span>
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="btn btn-base btn-lg"
                                    onClick={handleOtpVerify}
                                    
                                  >
                                    Register
                                  </button>
                                )}
                              </div>
                            ) : (
                              <div className="d-flex justify-content-center mx-4 mb-3 mb-lg-4">
                                {loadingBtnShow ? (
                                  <button
                                    className="btn btn-base"
                                    style={{
                                      background: "#fa4318",
                                      color: "white",
                                    }}
                                    type="button"
                                    disabled
                                  >
                                    <span
                                      className="spinner-border spinner-border-sm"
                                      aria-hidden="true"
                                    ></span>
                                    <span role="status">Loading...</span>
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="btn btn-base btn-lg"
                                    onClick={handleOtpSend}
                                    disabled={resBtnDisable}
                                    style={{
                                      background: "#fa4318",
                                      color: "white",
                                    }}
                                  >
                                    OTP Send
                                  </button>
                                )}
                              </div>
                            )}
                            <p className="small fw-bold mt-2 pt-1 mb-0">
                              Already have an account?{" "}
                              <Link
                                to="/signin"
                                className=""
                                style={{ color: "var(--main-color)" }}
                              >
                                Login Here
                              </Link>
                            </p>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Footer One */}
          <FooterOne />
          {/* Footer Bottom One */}
          <FooterBottomOne />
        </Suspense>
      </Fragment>
    </>
  );
};

export default UserSignup;
